import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "work-at-coqui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#work-at-coqui",
        "aria-label": "work at coqui permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👩‍💻Work at Coqui`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`We’re still hiring!`}</p>
    <p>{`An open source remote-friendly Berlin based startup founded by the creators of Mozilla’s
text-to-speech (TTS) and speech-to-text (STT) engines (over 725K downloads and 28K GitHub
stars), with the backing of top-flight investors `}<em parentName="p">{`and`}</em>{` we’re hiring!`}</p>
    <p>{`What’s not to love?`}</p>
    <p>{`We’re hiring across-the-board for a number of roles; so, there’s something for everyone:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/head-of-product"
        }}>{`Head of Product`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-full-stack-engineer"
        }}>{`Senior Full Stack Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-stt-deep-learning-engineer"
        }}>{`Senior STT Deep Learning Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-tts-deep-learning-engineer"
        }}>{`Senior TTS Deep Learning Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-developer-community-manager"
        }}>{`Senior, Developer Community Managers`}</a></li>
    </ul>
    <p>{`The full list of open positions is available on our `}<a parentName="p" {...{
        "href": "/jobs"
      }}>{`jobs page`}</a>{`.`}</p>
    <p>{`We’d love to hear from you; so, if any roles pique your interest, reach out to
`}<a parentName="p" {...{
        "href": "mailto:jobs@coqui.ai"
      }}>{`jobs@coqui.ai`}</a>{`. 🐸!`}</p>
    <h3 {...{
      "id": "welcome-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#welcome-",
        "aria-label": "welcome  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Welcome! 👋🐸`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`A new month, a new batch of Coqui goodness!`}</p>
    <p>{`This month we’ve a post-game look at our GTC presentation where we talked
about the long tail of languages, how the majority of the world speaks a
“minority” language, and what Coqui’s doing about it. Also, we’ll go into
some detail on the (secret) special surprise that the lecture launched,
the Long Tail Language Competition!`}</p>
    <p>{`We’ll cover the details of the Long Tail Language Competition: participants
who raised the bar for open speech technology, how much they raised it by,
and the details of a few participants who `}<em parentName="p">{`really`}</em>{` stood out.`}</p>
    <p>{`Not to over rotate on STT, we’ll also cover our first TTS Community Meeting,
or TTS Community Meeting v0.5 as it’s affectionately come to be known. We
had a great turnout, lots of questions, lots of answers, and lots of TTS
goodness.`}</p>
    <p>{`In this newsletter we’ll also cover an extremely useful addition to the STT
repo, super efficient transcription of long recordings. So, if you’ve been
sleeping on transcribing that 50 thousand hours of speech you have sitting
around on your harddrive. Now’s the time!`}</p>
    <p>{`Enjoy!`}</p>
    <h3 {...{
      "id": "coqui-at-gtc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-at-gtc",
        "aria-label": "coqui at gtc permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui at GTC`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACfUlEQVQ4y3VT60tTYRjfP9O3bjAkslXQF8uJmOmKXGXXpaOCID+oSMSIok9REBJGmRXDIV7SlpikM6MbNYPlbSor1thql7Ozc9k526/3fes9Ozp64Dnbec7z/J7fc7MUi0VQpcL/m981vQCdaKGw3k5FkWVkMhlIkkS+F5jNshGsQNUUzIWBEjUnlAlQMplEPp83MCzmjBtF03WksxJUVTVs1JOzkRUF6XSaJNNLgByMMognRSxHfiO4EAM1h1aiqL5wD46OXrTdHUYoOA+opeA8SSQIQjnDn3EBUx/X8CKwhLHpJQy++kZY6fi69B3bHddg3XcG1dt2o3OXFXdaGhH7tcoAVEVGlgDSCjgxiyip8AcWCdAixmfDmHi7gtGpecK4SNjGscNWDXvlVhyvr0SDYw/sDRXouupmtSuqglQqBVEUS4CJZJYxm3y/xgCpPh6YRjQahf/5COrsNajbX4XGxiOod55CrbMFB892kt4J0DWN9TCXy5WmLOZkPBt6A5//MybfreGhL4CpwAfMjI/A09UO17GjcJ0+CXfTIXQ0O9F62A5Xcy00QihPSk6nU1DIcAxA+liNxPDI+xLXbz/ByOhrhL58wmBfD3rvd6PtykV43OdwovUS6qsqYd20Bf1eLwuWZJkxlMlv2ZRVNY+F8A/EI2HcuHkLno7LaHefx94aOw5UbIZtpw0OZxMmxoaMFVLVv2tTNmW6yFwUKYvAzCxGhwfQ/7QP3T0PMOjzYi44xy4Cpj3UCFA2Kxh7uo4hu5J/jv8T7sNjNDIUOmHaQ8rSYGi+FnMQP8WNl2TcMgFKJBJsypzlOkAzKHWgAWalNt4vzpDaKAFe3R8IOOnRc8gHkQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/7049cfe0fa851646e1fba39a9398224d/da8b6/longtail_languages.png",
          "srcSet": ["/static/7049cfe0fa851646e1fba39a9398224d/43fa5/longtail_languages.png 250w", "/static/7049cfe0fa851646e1fba39a9398224d/c6e3d/longtail_languages.png 500w", "/static/7049cfe0fa851646e1fba39a9398224d/da8b6/longtail_languages.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`On November 10th Coqui was given the honour of presenting at NVIDIA’s GTC
conference in the ”`}<a parentName="p" {...{
        "href": "https://events.rainfocus.com/widget/nvidia/nvidiagtc/sessioncatalog?search=A31147&search=A31147"
      }}>{`From Speech to Text: How Does AI Understand the Spoken
Word?`}</a>{`”
session.`}</p>
    <p>{`Our presentation focused on language’s long tail.`}</p>
    <p>{`The world has roughly 7K languages, but there are only a small number of
languages that have over 50 million native speakers. However, there are
a large number of languages with under 50 million native speakers. As of
2011 there were over `}<em parentName="p">{`3.1 billion`}</em>{` native speakers of these “long tail
languages”. This leads to the seemingly contradictory conclusion that the
majority of the world speaks “minority” languages?!`}</p>
    <p>{`Intrigued? Here’s the recording of the presentation:`}</p>
    <div align="center">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/7S0u9eSR664" frameBorder="0" allow="accelerometer;
        autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <p>{`Also, at the lecture’s end we included a little surprise, the launching of
the `}<em parentName="p">{`Long Tail Language Competition`}</em>{`!`}</p>
    <h3 {...{
      "id": "long-tail-language-competition",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#long-tail-language-competition",
        "aria-label": "long tail language competition permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Long Tail Language Competition`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "24.400000000000002%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABeklEQVQY0xWPy04aAQBF+aUuu+iim7rpwqTV2EiNTUzaGOMrMeiiqRRN1ChFMRY1qCk6C8XhMQEZRURbFAeEgXkBgrYao39xnC5ucjf35B6HXghjFEVaVopVzwSTo8NYKT9/I/MY8U1OQ0vIOz6kXz7iwgqn0gZKRiB3uEXhZJtKPoxZimFVJGpaAod+LmBchvlXO8Az2Merl2/I/OjHmO1AFeZIeF0Evw3hcw0Q3/ZzmQ5hFkS0/K6928UsRqiVoljlGPX/QC0dpJre4jonEAvN0+3sxe1sJ+h8gex+z8p4D1+/9JCLBXg0ktwU92leidxW7V5N0VKTtCpJLGWPurKPoyoHqJ8JNJUEDfv+5oKbrrY2hj90sjzyieCUC+VI4KmRxbJtTDs12+ih8Yc7VeZeP+ZeS3N9sYd1FsKRF79TlmZQD7wo4gyl+DTRwBgjH9/y+d1rfoc9NLN+KolptONFyoc+VHkJPfMTM7OGfuRHTXopSQsY2XWeAQFkLXysgY4ZAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/716dd409334ec64c3122328a715d624b/da8b6/longtail_quail.png",
          "srcSet": ["/static/716dd409334ec64c3122328a715d624b/43fa5/longtail_quail.png 250w", "/static/716dd409334ec64c3122328a715d624b/c6e3d/longtail_quail.png 500w", "/static/716dd409334ec64c3122328a715d624b/da8b6/longtail_quail.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`For one week in November, community members (new and old) put their all into training
`}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/stt"
      }}>{`Speech-to-Text`}</a>{` models for their favorite languages
for the first ever Coqui Long Tail Language Competition. Why “Long Tail”? Because there’s
a long tailed distribution of languages in the world without access to modern speech
technologies.`}</p>
    <p>{`We want to give developers and communities the tools and knowledge to build their own
tech for their own languages. The data came from Mozilla’s Common Voice project, and
the competition was sponsored by `}<a parentName="p" {...{
        "href": "https://www.ovhcloud.com"
      }}>{`OVHcloud`}</a>{`. OVHcloud
generously donated seven days of free NVIDIA V100 GPU time to each participant!`}</p>
    <p>{`All the participants raised the bar for Open speech technology, but a few really stood
out. The winners went above and beyond, submitting the best Speech-to-Text model for a
language and vocabulary.`}</p>
    <ul>
      <li parentName="ul">{`Abraham Jerry Kakooza (`}<a parentName="li" {...{
          "href": "https://twitter.com/KakoozaJerry"
        }}>{`Twitter`}</a>
        <a parentName="li" {...{
          "href": "https://twitter.com/AIR_lab_MUK"
        }}>{`Makerere AI Lab`}</a>
        <a parentName="li" {...{
          "href": "https://twitter.com/oscafrica"
        }}>{`Open Source Community Africa`}</a>{`)`}</li>
      <li parentName="ul">{`Alp Öktem (`}<a parentName="li" {...{
          "href": "https://twitter.com/OktemAlp"
        }}>{`Twitter`}</a>
        <a parentName="li" {...{
          "href": "https://twitter.com/CLEARGlobalOrg"
        }}>{`CLEAR Global`}</a>
        <a parentName="li" {...{
          "href": "https://twitter.com/collectivat"
        }}>{`Col·lectivaT`}</a>{`)`}</li>
      <li parentName="ul">{`Anas Hasni (`}<a parentName="li" {...{
          "href": "https://twitter.com/hasnii_anas"
        }}>{`Twitter`}</a>{`)`}</li>
      <li parentName="ul">{`Dmitry Gaynullin (`}<a parentName="li" {...{
          "href": "https://github.com/GaynullinDima"
        }}>{`Github`}</a>{`)`}</li>
      <li parentName="ul">{`Mohammed Belkacem (`}<a parentName="li" {...{
          "href": "https://wiki.mozilla.org/L10n:Teams:kab"
        }}>{`the Kabyle community`}</a>{`)`}</li>
      <li parentName="ul">{`SefamerveArge`}</li>
      <li parentName="ul">{`tensorflowz`}</li>
      <li parentName="ul">{`Yurii Paniv (`}<a parentName="li" {...{
          "href": "https://twitter.com/robinhad"
        }}>{`Twitter`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/robinhad/"
        }}>{`Github`}</a>{`)`}</li>
    </ul>
    <p>{`Models were geared for either large vocabulary, i.e. transcription, or small vocabulary,
i.e. command-and-control scenarios. Winning models were trained from scratch or used
transfer-learning to bootstrap from a pre-trained model.`}</p>
    <p>{`Fun fact: smallest winning model == 138.5 KB!`}</p>
    <p>{`The competition is officially over, but you can take a look at the leaderboards (at least
one per language) `}<a parentName="p" {...{
        "href": "/ovh"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Thanks again to all those who participated!`}</p>
    <h3 {...{
      "id": "-tts-community-meeting-v05",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-tts-community-meeting-v05",
        "aria-label": " tts community meeting v05 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🐸💬 TTS Community Meeting v0.5`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "150%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAUGBAP/xAAWAQEBAQAAAAAAAAAAAAAAAAAFBgT/2gAMAwEAAhADEAAAAZ2nQ7dCFydhaBjWsDrKta8Qih3/xAAbEAACAwEBAQAAAAAAAAAAAAACAwQFBgESE//aAAgBAQABBQIH8rpEbSkqE5S3npY/hBB1ldmnE+pjwHy3UBg7lPPilW6WxaAZm5Nb5mL+r//EACQRAAEDAgQHAAAAAAAAAAAAAAECBBEAAxIhIvAFExQxQmGh/9oACAEDAQE/AXlzqkKZNRhKfHIZbiuVi1YqYN0uLK39oaiIVP2N9qVwNsoyi9A9g1//xAAdEQABBAMBAQAAAAAAAAAAAAABAAIREgMhQQQT/9oACAECAQE/AZY6vof3u0X0NYTMeRppkIgHUL6u6F//xAAlEAACAQQBBAEFAAAAAAAAAAABAgMABBExEhMhIkFCUVJhcdH/2gAIAQEABj8CV5cjY1nNNOtqWQvx3XUMaHl3Bx6qJsl5mfxBPbH6praONYZoE4svxf8ANRmbYJVc/SpL24YGGEsIlY6Gd091L4LLlhz+3Q/tQhbhF45BDnHuhZI5WJsFgPdLYz5mtnHBEOlpntp+jEfgwziv/8QAHxAAAgICAgMBAAAAAAAAAAAAAREAITFhUXFBgcGh/9oACAEBAAE/IRYKKLkyIIKNjxya8DmUQcKSywXAvlvJRN7V+wVogbQWV7v3M+aGa0Poi8CEkF0AG4ThEWlRQ9P2htODdAt8U5LHAnnVYhDanhL0cgwYysuN0Xif/9oADAMBAAIAAwAAABC4JfP/xAAcEQEBAAIDAQEAAAAAAAAAAAABESFBADGBUWH/2gAIAQMBAT8QQuykShq+FQDO057mdb9415RR1WqvkCfgM2p7XoUh8UwptMPP/8QAHxEBAAIBAwUAAAAAAAAAAAAAAREhQQAxcVFhgZGx/9oACAECAQE/ECdpaNkY+5ZrA6kFOK9eNbqiERRsPdb56jBns5Nf/8QAGxABAAIDAQEAAAAAAAAAAAAAAREhADFRQWH/2gAIAQEAAT8QKwPhIEBzy9FZaJE+OIUCSmBYvIquEWSQhYiXggWk4zEC0J5pMzW5ypBxOEMnQmsi5YqJPsNggPAwNgXrEIwALNlvwwPYSgypXhvdXMElkYd9OxSH7lUUHEaAW4gX071lmnHIVRoFIjMebTFo5GSCyAOImzXmf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/331248738385fef074659bc5b10c52f8/dbdff/community.jpg",
          "srcSet": ["/static/331248738385fef074659bc5b10c52f8/0988f/community.jpg 250w", "/static/331248738385fef074659bc5b10c52f8/d1f95/community.jpg 500w", "/static/331248738385fef074659bc5b10c52f8/dbdff/community.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`Last week we organized 🐸 TTS Community Meeting v0.5. Our first live community event!`}</p>
    <p>{`Many questions about 🐸TTS were asked and many questions about 🐸TTS were answered.
We discussed anything from techniques for improving TTS models to dealing with
deprecated method calls. Big kudos to everyone who tuned in.`}</p>
    <p>{`Don’t worry if you missed it. You can watch the live recording below.`}</p>
    <div align="center">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/2yup-Eeebq0" frameBorder="0" allow="accelerometer;
        autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <p>{`We are looking forward to the next one!`}</p>
    <p>{`In the meantime, feel free to share your comments and feedback and let us know what
you want to see in our next meeting.`}</p>
    <h3 {...{
      "id": "efficient-transcription-of-longer-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#efficient-transcription-of-longer-recordings",
        "aria-label": "efficient transcription of longer recordings permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Efficient Transcription of Longer Recordings`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "36%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAYB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwAC/9oADAMBAAIQAxAAAAGEoAW0Zv/EABkQAQACAwAAAAAAAAAAAAAAAAMCBBITIv/aAAgBAQABBQJ4qSVrHA56f//EABgRAAMBAQAAAAAAAAAAAAAAAAABAgMR/9oACAEDAQE/AaptorW+n//EABgRAAMBAQAAAAAAAAAAAAAAAAABAgMR/9oACAECAQE/AZniZOUtH//EACIQAAEDAwMFAAAAAAAAAAAAAAEDERIAAiEEIjETI0Gh0f/aAAgBAQAGPwLopspqFDCT8F81NSAtyBjw59fKtPb3Z3WOea//xAAaEAEBAQADAQAAAAAAAAAAAAABESEAMVFB/9oACAEBAAE/IT6EFYU6F6xl37yFCgpagPPpnuYbnU0UCFoi085//9oADAMBAAIAAwAAABDwP//EABYRAQEBAAAAAAAAAAAAAAAAAAEAYf/aAAgBAwEBPxB2cjMG/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQBh/9oACAECAQE/EAMbFVL/xAAZEAEBAQEBAQAAAAAAAAAAAAABESEAQVH/2gAIAQEAAT8QAojjpre2zaOH0Qeo+WS6qgWKAdAx0kN1GyK++Fe//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/14c49ca2e573a85218ec452140188539/dbdff/parallel_bees.jpg",
          "srcSet": ["/static/14c49ca2e573a85218ec452140188539/0988f/parallel_bees.jpg 250w", "/static/14c49ca2e573a85218ec452140188539/d1f95/parallel_bees.jpg 500w", "/static/14c49ca2e573a85218ec452140188539/dbdff/parallel_bees.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/reuben"
      }}>{`Reuben Morais`}</a></p>
    <p>{`The official Coqui STT packages offer best-in-class support for real-time, single-stream,
low-latency speech recognition. They’re highly tuned for fast start-up, low disk space
and memory utilization, and low-latency transcription results. On the other hand, the
pre-trained models in the Coqui Model Zoo can be useful for a number of tasks which don’t
have these performance constraints. One such case is offline transcription of longer
recordings — for example, imagine you have several hour-long recordings of lectures and
you want to generate automated captions.`}</p>
    <p>{`While one could use the 🐸STT command-line clients to transcribe these longer recordings
directly, a more efficient way to handle such cases is by identifying silent sections in
the recording, splitting it into smaller chunks using these silent sections, and then
transcribing the chunks in parallel. We had a script in the repository for handling
this case for a while now, but since it wasn’t properly documented, not many people knew
about it.`}</p>
    <p>{`On the latest main branch we’ve cleaned up the code, included it in the training package,
and `}<a parentName="p" {...{
        "href": "https://stt.readthedocs.io/en/latest/Checkpoint-Inference.html"
      }}>{`documented its usage`}</a>{`.
A typical usage of the script looks like this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "38.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABhklEQVQoz5WSuWoCURSGxdYqheIy474iLqOoIELEHewUC7FI4UyGqIivIAgWvoJgmwfwGQwzvoMSUWsL+z9z7jCJJFUGPv57Fv5zuHdMn4cD9vsPKHsViqL8G1VVsdvtcL1eQZ/pfbPBZPIC8U2GJIqQJAmipgYUG7nfNUKWZQwGA2y3W93wdTiEzfYEjufBczwcDgc4jgOvxaQul4up2+2G0+lk+pj3+Xwwm81YLBa64XQ6RTotoPxcRqFQQCqV0uI0BEFANptFJpNhuVgsxkgkEoxkMol4PM7qdrsdq9VKN5zNZohEIigWi8jlcqyBmv1+P7xeL6LRKKsHAgEEg0GEQiG2FZ1pQDgchtVq/TEcj8dsfTIiaKt6vY52u8202+0yer0eOp0O66HrIOgKPB4PLBYLlsulbjifz1EqldBqtVCr1VCpVNDv90FXMRqNQAMfoUHVahXNZpNpo9FAPp/Her3WDW+3G87nM3t2g9PphIP2Ox2Pxz8YvZfL5bufzvf7nRl+Aardb6jzlqLMAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/16f11490f31cb545986eb3eceee3745f/da8b6/efficient_transcription.png",
          "srcSet": ["/static/16f11490f31cb545986eb3eceee3745f/43fa5/efficient_transcription.png 250w", "/static/16f11490f31cb545986eb3eceee3745f/c6e3d/efficient_transcription.png 500w", "/static/16f11490f31cb545986eb3eceee3745f/da8b6/efficient_transcription.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The revamped transcription script (`}<inlineCode parentName="p">{`python -m coqui_stt_training.transcribe`}</inlineCode>{`) can recursively
scan directories for audio files and transcribe them using all available CPUs. The
transcription results are saved in JSON format, with one time coded transcription result
for each chunk, making it easier to process the results programmatically.`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      